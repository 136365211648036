





























import DialogMixins from '@/components/dialogs/mixins/DialogMixins'
import Dialog from '@/calendesk/models/Dialog'
import { DialogTypes } from '@/components/dialogs/DialogTypes'
import DialogSize from '@/calendesk/models/DialogSize'
import mixins from 'vue-typed-mixins'
export default mixins(DialogMixins).extend({
  name: 'SignUpDialog',
  components: {
    SignUpDialogForm: () => import('./SignUpDialogForm.vue')
  },
  methods: {
    loginHandler () {
      this.openDialog2(new Dialog(true, DialogTypes.LOGIN, this.$trans('login'), DialogSize.MIDDLE))
    }
  }
})
